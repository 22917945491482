<template>
    <!--    경매 출품 레이아웃-->
    <div class="mypage_auction_exhibit_wrap">
        <!--    레이아웃-->
        <table class="my_history_table" width="100%" border="0" cellspacing="0" cellpadding="0"
               style="table-layout: auto; width: 1200px; table-layout: fixed;">
            <colgroup>
                <col width="94px"/>
                <col width="180px"/>
                <col width="280px"/>
                <col width="145px"/>
                <col width="132px"/>
                <col width="190px"/>
                <col width="175px"/>
            </colgroup>
            <tr class="title_row">
                <th colspan="1">No</th>
                <th colspan="1">{{ $t('image') }}</th>
                <th colspan="1">{{ $t('subject') }}</th>
                <th colspan="1">{{ $t('status') }}</th>
                <th colspan="1">{{ $t('max_bid') }}</th>
                <th colspan="1">{{ $t('bid_history') }}</th>
                <th colspan="1">{{ $t('exhibitDate') }}</th>
            </tr>
            <tr v-for="(data,index) in itemData" :key="`item${index}`">
                <td class="move_detail_btn" @click="movePage(data)">{{ tableIdx(index) }}</td>  <!-- No -->
                <td class="move_detail_btn" @click="movePage(data)"><img :src="returnThumbnail(data)" width="78" height="49"/></td>
                <td class="move_detail_btn t_subject" @click="movePage(data)">{{ data.i_name }}</td>  <!-- 아이템명 -->

                <td class="move_detail_btn" @click="movePage(data)">{{ checkBidStatus(data) }}</td>  <!-- 상태 -->

                <td class="move_detail_btn" @click="movePage(data)" v-if="data.m_status !== 5">{{ returnCommas(data.m_bid_price)}}</td> <!-- 금액 -->
                <td class="move_detail_btn" @click="movePage(data)" v-else>-</td>
                <!--        경매내역 -->
                <td class="cursor" v-if="data.m_status !== 5">
                    <button @click="openModal('auction-history-modal',data.m_idx)">
                        {{ $t('bid_history') }}
                    </button>
                </td>
                <td class="move_detail_btn" @click="movePage(data)" v-else>-</td>

                <td class="move_detail_btn" @click="movePage(data)">{{ data.created_at }}</td>  <!-- 일시 -->
            </tr>
        </table>
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import priceMixins from "@/mixins/priceMixins";

export default {
    name: "ItemListAuctionExhibitLayout",
    mixins: [imageOption,priceMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
        paginationData: {default: {total: 0}},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        movePage(data) {
            this.$router.push(`/item/${data.i_sku}`);
        },
        checkBidStatus(data) {
            if (data.m_status === 1) {
                return this.$t('auction_process')
            } else if (data.m_status === 2 || data.m_status === 3 || data.m_status === 4) {
                return this.$t('s_bid')
            } else {
                return this.$t('burial')
            }
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(78, 49, 'jpg')}`;
            }
        },
        openModal(name,data) {
            this.$modal.show(name,{m_idx : data});
        },


    },
    watch: {},
}
</script>

<style scoped>

</style>
